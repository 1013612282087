import type { CoreFetch } from '~/types/globalDeclarations/CoreFetch'
import type { IndexPartialInfluencer } from '~/types/influencer'
import type { Locale } from '~/types/locale'

export function provideGetWelcomeReferences(coreFetch: CoreFetch) {
  return async function (
    locale: Locale,
    fallbackLocale: Locale = 'en',
  ): Promise<IndexPartialInfluencer[]> {
    try {
      return (
        (await coreFetch.$get<IndexPartialInfluencer[]>(
          `/welcome/sexy-users/`,
          {
            query: {
              lang: locale,
              fallback_lang: fallbackLocale,
            },
          },
        )) || []
      )
    } catch (e) {
      return []
    }
  }
}
